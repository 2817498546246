.blog-header {
  width: 100%;
  background-color: #5594cf;
  background-image: linear-gradient(315deg, #4f2769 0%, #5594cf 74%);
  color: white;
  padding: 70px 0 10px 0;
}
.slide-btn {
  width: 60%;
  text-align: right;
  padding-right: 30px;
  transition: 0.8s;
}
.slide-btn:hover {
  padding-right: 3%;
}

.blog-card {
  margin-top: 50px;
  height: 100%;
}
.blog-cards {
  margin-bottom: 50px;
}
.blog-card-img {
  object-fit: cover;
  height: 250px;
}
a:hover {
  text-decoration: none;
}
.blog-card a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}
.blog-card a:hover {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

.blog-title {
  font-size: 40px;
  /* text-transform: lowercase; */
  line-height: 0.8em;
  margin-bottom: 5px;
}
.blog-date {
  font-size: 16px;
  text-transform: capitalize;
}
.blog-content p {
  font-size: 16px;
  text-indent: 30px;
  padding: 0;
  margin-bottom: 20px;
  line-height: 1.6em;
}

.blog-content img {
  border-radius: 5px !important;
  max-height: 300px;
  margin-right: 30px;
  margin-left: 30px;
  margin-bottom: 15px;
  max-width: 100%;
}
.blog-content p:nth-child(even) img {
  float: left;
}
.blog-content p:nth-child(odd) img {
  float: right;
}

/* .blog-content > * p img:nth-child(odd) {
  float: right;
}
.blog-content > * p img:nth-child(even) {
  float: left;
} */

.blog-content {
  margin-top: 50px;
  margin-bottom: 50px;
}

.new {
  position: absolute;
  right: -80px;
  top: 20px;
  font-size: 16px;
  transform: rotate(45deg);
  overflow: hidden;
  padding: 5px 100px;
  text-align: center;
}
.card {
  overflow: hidden;
}
.back-button {
  width: 100%;
}
.back-button:focus {
  outline: none;
}
.blog-tag {
  color: #4f2769;
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid;
  font-style: italic;
  /* float: right; */
}

/* The container must be positioned relative: */
.custom-select {
  width: 30%;
  text-align: center;
  margin-left: 10px;
  border: none;
}

.custom-select:hover {
  cursor: pointer;
}

@media only screen and (max-width: 480px) {
  .blog-content img {
    width: 100%;
    max-height: inherit;
    margin: 20px 0;
    /* padding: 0; */
  }
}
