.policy {
  color: #5594cf;
  padding: 50px;
}

.policy h4,
.policy p {
  color: #211e5d;
}
.policy-section {
  padding-left: 50px;
  padding-bottom: 20px;
}
.privacy-policy {
  text-align: center;
  display: block;
}
