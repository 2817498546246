.footer {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  background-image: url("../images/city.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  will-change: transform;
}

.footer-overlay {
  background-color: rgba(85, 148, 207, 0.4);
  height: 100%;
  width: 100%;
  background-image: linear-gradient(315deg, #bdd4e75b 0%, #8693ab80 74%);
  will-change: transform;
}

.footer-logo {
  display: block;
  margin: 15px auto;
  height: 70px;
}

@media only screen and (max-width: 480px) {
  .footer {
    background-attachment: scroll;
    background-image: url("../images/city_mobile.png");
  }
}
