.resume {
  background-color: #cce9ff;
  /* margin-top: 70px; */
  padding-top: 70px;
  color: #211e5d;
  font-size: 16px;
  border-top-right-radius: 200px 200px;
  border-bottom-left-radius: 200px 200px;
}

.headshot {
  width: 250px;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
}
.resume-name {
  font-size: 50px;
  text-transform: lowercase;
  color: #5594cf;
  text-align: center;
  letter-spacing: 3px;
  padding-top: 20px;
}
.resume-socials {
  padding-left: 0;
}
.resume-socials .col-12 {
  display: inline;
  border-right: 1px solid;
  padding: 0px 10px;
}
.resume-socials .col-12:last-child {
  border-right: none;
}
.resume a {
  color: inherit;
  text-decoration: none;
}

.hover {
  cursor: pointer !important;
}

.resume-intro {
  padding-top: 40px;
  padding-bottom: 30px;
  text-align: justify;
}

.resume-button {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  font-size: 50px;
  padding-top: 10px;
  margin: auto;
}

.resume-visible {
  opacity: 1;
  transition: 0.5s;
  width: 100%;
}
.resume-invisible {
  opacity: 0;
  transition: 0.5s;
  height: 0;
  overflow: hidden;
}

.resume-header {
  font-size: 30px;
  margin-bottom: 20px;
  color: #5594cf;
  text-align: center;
  width: 100%;
}
.resume-item {
  padding-bottom: 15px;
  padding-top: 25px;
  border-bottom: 1px solid #5594cf;
}
.resume-item:last-child {
  padding-bottom: 50px;
  border-bottom: 0px !important;
}
.resume-date {
  text-transform: uppercase;
  color: #4f2769;
  margin: 0;
}
.resume-title {
  display: inline;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 20px;
}
.resume-location {
  display: inline;
  padding-left: 20px;
  text-transform: uppercase;
  font-size: 20px;
  color: #4f2769;
}
.resume-bio {
  margin-top: 10px;
  text-align: justify;
}
.resume-highlights {
  list-style-type: circle;
}

.resume-skill-section {
  padding-top: 15px;
  padding-bottom: 30px;
}

.resume-skill-title {
  font-size: 24px;

  text-transform: lowercase;
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .resume-location {
    display: block;
    padding-left: 0px;
  }
  .resume-intro,
  .resume-bio {
    text-align: left;
  }
  .resume-name {
    font-size: 40px;
  }
}
