/* See article on what I'm doing here */
/* Also see script in index.html */
/* https://css-tricks.com/the-trick-to-viewport-units-on-mobile/ */
.h10 {
  height: 10vh;
  height: calc(var(--vh, 1vh) * 10);
}
.h20 {
  height: 20vh;
  height: calc(var(--vh, 1vh) * 20);
}
.h30 {
  height: 30vh;
  height: calc(var(--vh, 1vh) * 30);
}
.h40 {
  height: 40vh;
  height: calc(var(--vh, 1vh) * 40);
}
.h50 {
  height: 50vh;
  height: calc(var(--vh, 1vh) * 50);
}
.h60 {
  height: 60vh;
  height: calc(var(--vh, 1vh) * 60);
}
.h70 {
  height: 70vh;
  height: calc(var(--vh, 1vh) * 70);
}
.h80 {
  height: 80vh;
  height: calc(var(--vh, 1vh) * 80);
}
.h90 {
  height: 90vh;
  height: calc(var(--vh, 1vh) * 90);
}
.h100 {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.mh10 {
  min-height: 10vh;
  min-height: calc(var(--vh, 1vh) * 10);
}
.mh20 {
  min-height: 20vh;
  min-height: calc(var(--vh, 1vh) * 20);
}
.mh30 {
  min-height: 30vh;
  min-height: calc(var(--vh, 1vh) * 30);
}
.mh40 {
  min-height: 40vh;
  min-height: calc(var(--vh, 1vh) * 40);
}
.mh50 {
  min-height: 50vh;
  min-height: calc(var(--vh, 1vh) * 50);
}
.mh60 {
  min-height: 60vh;
  min-height: calc(var(--vh, 1vh) * 60);
}
.mh70 {
  min-height: 70vh;
  min-height: calc(var(--vh, 1vh) * 70);
}
.mh80 {
  min-height: 80vh;
  min-height: calc(var(--vh, 1vh) * 80);
}
.mh90 {
  min-height: 90vh;
  min-height: calc(var(--vh, 1vh) * 90);
}
.mh100 {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}
