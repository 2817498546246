.contact {
  overflow: hidden;
  background-color: #f1f9ff;
  padding-bottom: 50px;
}
.contact-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.contact-toggler {
  margin-top: 50px;
}
.contact-toggler .button {
  width: 100px;
  height: 50px;
  margin: 0;
  border: none;
  transition: 0.5s !important;
  font-size: 20px !important;
  padding: 15px;
}
.contact-toggler .button:first-child {
  border-radius: 5px 0 0 5px;
}
.contact-toggler .button:last-child {
  border-radius: 0 5px 5px 0;
}

.contact-forms {
  width: 100%;
  overflow: hidden;
  margin-top: 50px;
  overflow: hidden;
}
.contact-form {
  transition: 0.5s !important;
  width: 0%;
  /* height: 0; */
  overflow: hidden;
}

.contact-form:first-child {
  float: right;
}

.contact-selected {
  width: 100% !important;
}
.contact-hidden {
  height: 0px;
  overflow: hidden;
}

.right-grad-selected {
  background-color: #5594cf !important;
  background-image: linear-gradient(90deg, #5594cf 0%, #211e5d 100%) !important;
  color: white !important;
  border-radius: 0 5px 5px 0 !important;
}
.right-grad-unselected {
  background-color: #5594cf !important;
  color: white !important;
}

.left-grad-selected {
  background-color: #5594cf !important;
  background-image: linear-gradient(-90deg, #5594cf 0%, #211e5d 100%) !important;
  color: white !important;
  border-radius: 5px 0 0 5px !important;
}

.left-grad-unselected {
  background-color: #5594cf !important;
  color: white !important;
}

.send-button {
  width: 66%;
}
.send-button:hover {
  background-color: #5b9fdf !important;
}

/* .ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ .coloring.black:before {
  background: #ff0033 !important;
} */
.ui.toggle.checkbox input ~ .coloring.error:before {
  background: #ff0033 !important;
}

.noshaddow {
  box-shadow: none !important;
}
.contact h2 {
  font-weight: bold;
  opacity: 0.7;
  font-size: 70px;
  /* margin-bottom: 3px; */
  padding-bottom: 0px;
  line-height: 30px;
  text-align: center;
}

@media only screen and (max-width: 480px) {
  .contact h2 {
    line-height: 90%;
    font-size: 40px;
    text-align: center;
  }
}
