.landing-services {
  margin: 0 auto;
  max-width: 1000px;
  padding-top: 100px;
}

.landing-services h3 {
  text-align: center;
  width: 100%;
}
.landing-services h2 {
  font-weight: bold;
  opacity: 0.7;
  font-size: 100px;
  margin-bottom: 3px;
  padding-bottom: 0px;
  line-height: 80px;
  text-align: center;
}

.landing-services-inner {
  border-radius: 15px;
  padding: 50px 30px;
}
.landing-services .service img {
  width: 50px;
}

.landing-services .service .p {
  text-align: center;
  font-size: 18px;
}

.showTextH0 {
  text-align: center;
  font-size: 18px;
  opacity: 100;
  transition: opacity 300ms 300ms;
}
.hiddenTextH0 {
  text-align: center;
  height: 0px;
  opacity: 0;
}

/* animating grow shrink */

.landing-services-inner-chunk {
  display: flex;
  flex-direction: row;
}
.landing-services .service {
  display: flex;
  margin: 15px;
  padding: 15px;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  transition-duration: 300ms;
  cursor: pointer;
}

.landing-services .service:hover {
  transform: scale(1.04);
}

.service-deactive {
  flex: 1;
}
.service-active {
  background-color: #61a4e31a;
  flex: 3;
}

@media only screen and (max-width: 730px) {
  .landing-services-inner-chunk {
    flex-wrap: wrap;
  }
  .landing-services .service {
    width: 100%;
  }
  .service-deactive {
    flex: auto;
  }
  .service-active {
    flex: auto;
  }
}

@media only screen and (max-width: 500px) {
  .landing-services h2 {
    font-size: 80px;
  }
  .landing-services .service {
    width: 100%;
  }
  .landing-services .service .p {
    font-size: 16px;
  }
}
