.faqs {
  background-color: #f1f9ff;
  padding-top: 50px;
  padding-bottom: 100px;
}

.faqs h2 {
  font-weight: bold;
  opacity: 0.7;
  font-size: 70px;
  /* margin-bottom: 3px; */
  padding-bottom: 0px;
  line-height: 30px;
  text-align: center;
}
/* CSS styles for our accordion component */
.Accordion {
  /* background-color: blue; */
}
.AccordionItem {
  margin: 20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.AccordionHeader {
  background-color: #5594cf;
  cursor: pointer;
  padding: 15px;
  font-size: 16px;
  color: white;
  transition-duration: 300ms;
  border-radius: 8px;
}
.AccordionHeader:hover {
  opacity: 0.8;
}
.AccordionBody .button {
  background-color: #5594cf !important;
  color: white !important;
  align-self: center;
  margin-top: 20px;
}
.AccordionBody {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 0;
  overflow: hidden;
  font-size: 16px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.AccordionItem.active .AccordionBody {
  height: auto;
  padding: 25px;
}

.buttoncentered {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 480px) {
  .faqs h2 {
    line-height: 90%;
    font-size: 40px;
    text-align: center;
  }
  .AccordionItem {
    margin: 15px 0;
  }
}
