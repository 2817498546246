body {
  max-width: 100%;
  overflow-x: hidden;
}
body::-webkit-scrollbar {
  display: none;
}
body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.grad {
  background-color: #000000;
  background-image: linear-gradient(315deg, #000000 0%, #414141 74%);

  background-color: #5594cf;
  background-image: linear-gradient(315deg, #4f2769 0%, #5594cf 74%);

  color: white;
}
.bw-blue-text {
  color: #5594cf !important;
}
.bw-blue {
  background-color: #5594cf !important;
  color: white !important;
}
.bw-purple {
  background-color: #4f2769 !important;
  color: white !important;
}
.bw-darkblue {
  background-color: #211e5d !important;
  color: white !important;
}
.bw-lightblue {
  background-color: #61a4e3 !important;
  color: white !important;
}

.bw-red {
  background-color: #ff0033 !important;
  color: white !important;
}

.banks {
  font-family: "banks_miles_single_lineRg" !important;
}

.bodini {
  font-family: "bodoni_06ptregular" !important;
}

.zoom {
  transition: transform 0.3s; /* Animation */
  cursor: pointer;
}

.zoom:hover {
  transform: scale(1.1);
}

.noHover {
  pointer-events: none;
}

textarea:focus,
input:focus,
button:focus {
  outline: none !important;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
  border-color: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  will-change: transform;
}

.shadow {
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

.train-bg {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  background-image: url("../images/train.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  will-change: transform;
}

.privacy-policy {
  color: white;
  text-align: center;
  transition: 0.5s;
}
.privacy-policy:hover {
  color: rgb(199, 199, 199);
  text-decoration: none;
}
.privacy-policy:focus {
  outline: none;
}
.spinner {
  /* width: 20%; */
  /* margin: 0 40%; */
  margin-top: 30vh;
  margin-bottom: 20vh;
  text-align: center;
}
.spinner p {
  font-size: 16px;
  margin-top: 30px;
}

.auth-form {
  /* width: 100vw !important; */
  margin: auto;
  height: 100vh;
  /* margin-top: ; */
}
.auth-form-inner {
  width: 60% !important;
  margin: auto;
  /* height: 100% !important; */
}

@media only screen and (max-width: 480px) {
  .train-bg {
    background-attachment: scroll;
    background-image: url("../images/train_mobile.png");
  }
  .auth-form-inner {
    width: 80% !important;
  }
}

.text-field {
  box-shadow: 0 2px 20px -2px rgba(0, 0, 0, 0.2) !important;
  webkit-box-shadow: 0 2px 20px -2px rgba(0, 0, 0, 0.2) !important;
  -webkit-appearance: none;

  /* height: 1px; */
}
form {
  -webkit-appearance: none;
}

.clients {
  padding-top: 100px;
  padding-bottom: 50px;
  /* background-color: #5594cf1f; */
  /* background-color: #fafafa; */
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, #5594cf0b 30%, #5594cf0b 70%, rgba(255, 255, 255, 1) 100%);
}
.clients h2 {
  font-weight: bold;
  opacity: 0.7;
  font-size: 40px;
  margin-bottom: 3px;
  padding-bottom: 0px;
  line-height: 80px;
  text-align: center;
}
