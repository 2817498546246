.nav-logo {
  height: 60px;
  margin: 15px auto;
  display: block;
}
.nav-logo:focus {
  outline: none;
}

.navbar {
  text-align: center;
  padding: 3px;
}

.nav-link {
  color: white !important;
  font-size: 20px;
  /* text-transform: lowercase; */
  padding: 25px 0px;
  text-align: center;
}
.nav-link:hover {
  color: rgb(220, 220, 220) !important;
}
.nav-link:focus {
  outline: none;
}

.menu-circle {
  height: 120px;
  width: 150px;
  border-radius: 0 0 0 50%;
  background-color: white;
  position: fixed;
  right: 36px;
  top: 36px;
  transform: translate(50%, -50%);
  z-index: 500;
  will-change: transform;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 30px;
  top: 30px;
  will-change: transform;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #211e5d;
  transition: 0.4s;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #9cb0c9;
  transform: scale(1.3);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 46px !important;
  width: 46px !important;
  margin-right: 30px;
  margin-top: 12px;
  transition: 0.4s;
}
.bm-cross-button:hover {
  transform: scale(1.3);
}

/* Color/shape of close button cross */
.bm-cross {
  background: white;
  height: 36px !important;
  padding-right: 5px !important;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  will-change: transform;
}

/* General sidebar styles */
.bm-menu {
  background-color: #9cb0c9;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #9cb0c9;
}

/* Wrapper for item list */
.bm-item-list {
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.4) !important;
  z-index: 999999 !important;
}
.bm-menu-wrap {
  z-index: 999999 !important;
}
