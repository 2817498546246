.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pagination .left,
.pagination .right {
  margin-right: 20px;
  margin-left: 20px;
  background-color: #5594cf;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  transition-duration: 200ms;
}
.pagination .left:hover,
.pagination .right:hover {
  opacity: 0.8;
  cursor: pointer;
}
.pagination .number {
  font-size: 16px;
}
