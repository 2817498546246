/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 15, 2021 */
/* font-family:  */
/* font-family: "banks_miles_single_lineRg" !important; */
/* font-family: "bodoni_72ptmedium_italic" !important; */
/* font-family: "bodoni_72ptmedium" !important; */
/* font-family: "bodoni_72ptfatface_italic" !important; */
/* font-family: "bodoni_72ptfatface" !important; */
/* font-family: "bodoni_72ptitalic" !important; */
/* font-family: "bodoni_72ptregular" !important; */
/* font-family: "bodoni_72ptbold_italic" !important; */
/* font-family: "bodoni_72ptbold" !important; */
/* font-family: "bodoni_06ptmedium_italic" !important; */
/* font-family: "bodoni_06ptmedium" !important; */
/* font-family: "bodoni_06ptfatface_italic" !important; */
/* font-family: "bodoni_06ptfatface" !important; */
/* font-family: "bodoni_06ptitalic" !important; */
/* font-family: "bodoni_06ptregular" !important; */
/* font-family: "bodoni_06ptbold_italic" !important; */
/* font-family: "bodoni_06ptbold" !important; */

@font-face {
  font-family: "banks_miles_single_lineRg";
  src: url("./fonts/banksmilessingleline-webfont.woff2") format("woff2"),
    url("./fonts/banksmilessingleline-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bodoni_72ptmedium_italic";
  src: url("./fonts/bodoni-72-medium-italic-webfont.woff2") format("woff2"),
    url("./fonts/bodoni-72-medium-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bodoni_72ptmedium";
  src: url("./fonts/bodoni-72-medium-webfont.woff2") format("woff2"),
    url("./fonts/bodoni-72-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bodoni_72ptfatface_italic";
  src: url("./fonts/bodoni-72-fatface-italic-webfont.woff2") format("woff2"),
    url("./fonts/bodoni-72-fatface-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bodoni_72ptfatface";
  src: url("./fonts/bodoni-72-fatface-webfont.woff2") format("woff2"),
    url("./fonts/bodoni-72-fatface-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bodoni_72ptitalic";
  src: url("./fonts/bodoni-72-book-italic-webfont.woff2") format("woff2"),
    url("./fonts/bodoni-72-book-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bodoni_72ptregular";
  src: url("./fonts/bodoni-72-book-webfont.woff2") format("woff2"),
    url("./fonts/bodoni-72-book-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bodoni_72ptbold_italic";
  src: url("./fonts/bodoni-72-bold-italic-webfont.woff2") format("woff2"),
    url("./fonts/bodoni-72-bold-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bodoni_72ptbold";
  src: url("./fonts/bodoni-72-bold-webfont.woff2") format("woff2"),
    url("./fonts/bodoni-72-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bodoni_06ptmedium_italic";
  src: url("./fonts/bodoni-06-medium-italic-webfont.woff2") format("woff2"),
    url("./fonts/bodoni-06-medium-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bodoni_06ptmedium";
  src: url("./fonts/bodoni-06-medium-webfont.woff2") format("woff2"),
    url("./fonts/bodoni-06-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bodoni_06ptfatface_italic";
  src: url("./fonts/bodoni-06-fatface-italic-webfont.woff2") format("woff2"),
    url("./fonts/bodoni-06-fatface-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bodoni_06ptfatface";
  src: url("./fonts/bodoni-06-fatface-webfont.woff2") format("woff2"),
    url("./fonts/bodoni-06-fatface-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bodoni_06ptitalic";
  src: url("./fonts/bodoni-06-book-italic-webfont.woff2") format("woff2"),
    url("./fonts/bodoni-06-book-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bodoni_06ptregular";
  src: url("./fonts/bodoni-06-book-webfont.woff2") format("woff2"),
    url("./fonts/bodoni-06-book-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bodoni_06ptbold_italic";
  src: url("./fonts/bodoni-06-bold-italic-webfont.woff2") format("woff2"),
    url("./fonts/bodoni-06-bold-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bodoni_06ptbold";
  src: url("./fonts/bodoni-06-bold-webfont.woff2") format("woff2"),
    url("./fonts/bodoni-06-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
