#root,
body,
.body {
  /* display: none; */
  overflow-x: hidden;
  max-width: 100vw;
}

.submit-button:hover {
  color: white;
}

textarea.error,
input.error {
  background-color: #ffa1b4;
}

.form.success,
.form.fail {
  text-align: center;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.ads textarea:focus,
.ads input:focus {
  background-color: #f5f5f5 !important;
}

.ad-landing-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
  position: relative;
  z-index: 1;
  gap: 20px;
  margin: 0 auto;
}
.submit-button.phone.outline {
  background-color: transparent !important;
  background: none;
  color: #63cbe4;
  border: 1px solid #63cbe4;
}
