.services {
  width: 100%;
}
.services-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(39, 69, 97, 0.4);
  padding-top: 100px;
  padding-bottom: 100px;
}

.accordion .card {
  border: none;
}

.accordion .card-header {
  transition: 0.2s !important;
  font-size: 20px;
  text-align: center;
  border-bottom: 2px white solid;
}

.accordion .card-header:hover {
  background-color: #5084b5 !important;
  cursor: pointer;
}
.accordion .card-body {
  font-size: 16px;
  text-align: justify;
}

.button-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

/* new */
.partnershiprtf {
  font-size: 16px;
}
.services h2 {
  color: white;
  font-weight: bold;
  opacity: 0.8;
  font-size: 100px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  line-height: 80px;
}
.portflio {
  margin-top: 0px;
  background-color: white;
  padding: 30px;
  border-radius: 8px;
}
.f16 {
  font-size: 18px;
}

.partnership {
  margin-top: 30px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  padding: 23px;
}

/* every other .partnership */
.partnership.odd {
  flex-direction: row-reverse;
}

.partnershipimg {
  display: inline-block;
  width: 35%;
  /* height: 100%; */
  /* background-color: #5084b5; */

  filter: url(#round);
  overflow: hidden;
}
.partnershipimg::before {
  background-color: #5084b5;
  content: "";
  display: block;
  padding-top: 100%;
  height: 100%;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 60% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 100% 0%, 60% 100%, 0% 100%);
  overflow: hidden;
  background-image: var(--bg-image);
  background-size: cover;
}

.odd .partnershipimg::before {
  height: 100%;
  -webkit-clip-path: polygon(60% 0%, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(60% 0%, 100% 0%, 100% 100%, 0% 100%);
}
.partnership .content {
  padding: 30px;
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.btncont {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.seemore {
  color: black !important;
  background-color: white !important;
  /* margin-left: auto; */
  /* margin-right: auto; */
  width: 200px;
}

.btns {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
}
.btns a {
  margin-bottom: 10px;
}
.sitebtn {
  background-color: #5084b5;
  color: white !important;
  /* padding: 10px 20px; */
  margin-right: 10px;
  border-radius: 8px;
  transition-duration: 300ms;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
}
.appleimg {
  /* margin-top: 10px; */
  height: 50px;
  margin: 0 10px;
}
.btns a:hover {
  opacity: 0.7;
}
@media only screen and (max-width: 600px) {
  .partnership {
    flex-direction: column;
  }
  .partnership.odd {
    flex-direction: column-reverse;
  }
  .partnership .content {
    width: 100%;
  }
  .partnershipimg {
    width: 100%;
    display: none;
  }
}
