.header {
  width: 100%;

  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  background-image: url("../images/bg2.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  will-change: transform;
}
.header-overlay {
  background-color: rgba(85, 148, 207, 0.4);
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(315deg, #bdd4e75b 0%, #8693ab80 74%);
  will-change: transform;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}

.header-container {
  min-height: 100vh;
  width: 100%;
  padding: 80px;
  display: flex;
  flex-direction: column;
}

.header-logo-full {
  align-self: baseline;
  justify-self: baseline;
  width: 250px;
  max-width: 100%;
  margin-bottom: 30px;
}

.header-row {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* flex: 1; */
  row-gap: 20px;
  column-gap: 20px;
  margin: auto;
  /* margin-bottom: 20px; */
  flex-wrap: wrap;
}

.header-card {
  flex: 1;
  flex-direction: column;
  background-color: #f5f5f5;
  padding: 30px;
  border-radius: 8px;
  min-width: 300px;
  max-width: 400px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  position: relative;
  text-align: center;
}
.header-icon {
  width: 80px;
  background-color: white;
  padding: 10px;
  border-radius: 100px;
  margin-bottom: 15px;
}

.calendly {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 20px;
  margin-bottom: 20px;
}
.calendly button {
  outline: none;
  background-color: #5594cf;
  color: white;
  padding: 15px 30px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 18px;
  border: none;
  transition-duration: 300ms;
  border: 2px solid white;
}
.calendly button:hover {
  opacity: 0.8;
}

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #5594cf;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0px;
}
.ribbon-top-left::after {
  bottom: 0;
  left: -10;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

/* bottom left*/
.ribbon-bottom-left {
  bottom: -10px;
  left: -10px;
}
.ribbon-bottom-left::before,
.ribbon-bottom-left::after {
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.ribbon-bottom-left::before {
  bottom: 0;
  right: 0;
}
.ribbon-bottom-left::after {
  top: 0;
  left: 0;
}
.ribbon-bottom-left span {
  right: -25px;
  bottom: 30px;
  transform: rotate(225deg);
}

/* bottom right*/
.ribbon-bottom-right {
  bottom: -10px;
  right: -10px;
}
.ribbon-bottom-right::before,
.ribbon-bottom-right::after {
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.ribbon-bottom-right::before {
  bottom: 0;
  left: 0;
}
.ribbon-bottom-right::after {
  top: 0;
  right: 0;
}
.ribbon-bottom-right span {
  left: -25px;
  bottom: 30px;
  transform: rotate(-225deg);
}

@media only screen and (max-width: 480px) {
  .header {
    background-attachment: scroll;
    background-image: url("../images/city_mobile.png");
  }

  .header-container {
    height: 100%;
    width: 100%;
    padding: 20px;
  }
}
