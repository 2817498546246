.portfolio-title {
  font-size: 100px;
  margin-bottom: -50px;
  opacity: 0.5;
  /* font-weight: 100; */
  letter-spacing: 5px;
}
.portfolio-content {
  padding-top: 80px;
}
.portfolio {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;

  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.portfolio-background {
  background: white;
  /* border-bottom-left-radius: 200px 200px; */
  /* border-top-left-radius: 200px 200px; */
  /* border-bottom-right-radius: 200px 200px; */
  /* border-bottom-right-radius: 0 0; */
  height: 100%;
  width: 100%;
  padding-bottom: 100px;
}

@media only screen and (max-width: 480px) {
  .portfolio {
    background-attachment: scroll;

    background-repeat: repeat;
    background-size: inherit;
    -webkit-background-size: inherit;
    -moz-background-size: inherit;
    -o-background-size: inherit;
    background-position-y: 200px;
    background-color: #5594cf;
  }
  .portfolio-title {
    font-size: 60px;
    padding-top: 50px;
  }
}

.portfolio-col {
  margin: 0 auto;
  margin-bottom: 50px;
}
.filterList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 50px;
}
