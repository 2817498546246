.project {
  margin: 20px auto;
  width: 25vh;
  width: calc(var(--vh, 1vh) * 25);
  display: inline-flex;
}
.project a {
  position: relative;
  width: 100%;
  height: 100%;
}
.project img {
  height: calc(var(--vh, 1vh) * 25);
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.project i {
  font-size: 25vh;
  font-size: calc(var(--vh, 1vh) * 25);
  position: absolute;
  top: 0;
  right: 0;
  color: aliceblue;
  transition: 0.5s;
}
.hidden {
  color: rgba(0, 0, 0, 0) !important;
}

.project-title {
  font-size: 20px;
  text-align: center;
  margin: 0;
  letter-spacing: 1px;
  color: #333;
}
.project-industry {
  font-size: 24px;
  text-align: center;
  margin: 0;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.portfolio-select {
  width: 80% !important;
  margin: 20px 10% !important;
}
