/* .quote-visible {
  width: 100%;
  height: 100px;
  transition: 5s;
  display: inline;
  overflow: hidden;
}
.quote-invisible {
  width: 0px !important;
  height: 0px !important;
  overflow: hidden;
  transition: 5s;
} */
.quote-page {
  overflow: hidden;
  transition: 5s; /*10x slower because height chnages to 1000vh*/
  transition-property: max-height;
}

.quote-toggler {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.quote-toggler-button {
  width: 50px;
  height: 20px;
  background-color: #5594cf;
  margin: 0px 0px;
  border-right: lightblue 1px solid;
  transition: 0.6s;
}
.quote-toggler-button:last-child {
  border-right: none;
  border-radius: 0 5px 5px 0;
}
.quote-toggler-button:first-child {
  border-radius: 5px 0 0 5px;
}

.quote-active,
.quote-toggler-button:hover {
  background-color: lightblue;
}
.quote-buttons .button {
  transition: 0.3s;
}

.quote-buttons .button:hover {
  background-color: #61a4e3 !important;
}

.quote-form {
  margin: auto 20px;
}

.quote-visible label:hover {
  cursor: pointer;
}

select {
  padding: 0px 10px !important;
}
