.cover {
  background-color: white;
  /* width: 300px; */
  height: 50px;
  margin-top: -50px;
  position: relative;
  z-index: 99999 !important;
}

.reviews-container {
  padding-top: 100px;
  /* padding-bottom: 100px; */
  background-color: #ffffff;
  max-width: 1200px;
  margin: auto;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}
.reviews {
  border-radius: 5px 5px 0 0 !important;
  padding-bottom: 200px;
}
.reviews img {
  filter: grayscale(100%);
  border-radius: 5px 5px 0 0 !important;
  object-fit: cover;
  width: 100%;
}
.overlay {
  position: absolute;
  border-radius: 5px 5px 0 0 !important;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 200;
  height: 100%;
  width: 100%;
}

.carousel-caption {
  z-index: 300;
}
.carousel-control-next,
.carousel-control-prev {
  z-index: 400;
}
.stars {
  font-size: 20px;
  color: rgb(212, 175, 55);
  margin-bottom: 10px;
}

.review-content {
  font-size: 16px;
}
.review-info {
  font-size: 14px;
}

.success-card {
  width: 100%;
  border-radius: 0px 0px 5px 5px;
}
.google-button {
  width: 100%;
  border-radius: 0px 0px 5px 5px !important;
}
.review-submit {
  width: 100%;
  border-radius: 5px;
}

.react-stars {
  margin: auto;
}
.react-stars:focus {
  outline: none;
}
.review-form-inner {
  margin-top: 20px;
  padding: 5px;
}

.error-msg {
  color: red;
}

.height-none {
  max-height: 0px;
  transition: 0.6s !important;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.height-full {
  max-height: 5000px;
  transition: 0.6s !important;
}

@media only screen and (max-width: 768px) {
  .reviews-container {
    padding-right: 10px;
    padding-left: 10px;
  }
  .reviews,
  .reviews img {
    height: 65vh !important;
  }
}

.carouselContent {
  margin-top: 50px;
}
.carousel-caption {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 0;
}
