.popup.closed {
  position: relative;
  height: auto;
  background-color: white;
  overflow: auto;
  z-index: 0;
}
.popup.closed .popup-inner {
  margin: 0;
  height: auto;
  width: auto;
}

.popup.closed .close-btn {
  display: none;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* overflow: scroll; */
}
.popup:not(.closed) .popup-bg {
  position: absolute;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
}
.popup.closed .popup-inner {
  margin-top: 50px;
}

.popup-inner {
  background-color: white;
  padding: 50px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 80vh; */
  width: 80vw;
  position: relative;
  /* overflow: scroll; */
}

.close-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 20px;
  cursor: pointer;
  padding: 20px;
}
.popup-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.popup-card {
  background-color: whitesmoke;
  width: 40%;

  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
}
.popup-card div {
  padding: 20px;
}
.popup-card img {
  width: 100%;
}
.contacts-row {
}

.popup h1 {
  /* max-width: 500px; */
  margin-bottom: 20px;
}
.popup .navicon {
  margin: 10px;
}

@media only screen and (max-width: 1000px) {
  .popup-card {
    width: 90%;
  }
  .popup-card img {
    height: 100px;
    object-fit: cover;
  }
  .popup h1 {
    display: none;
  }
  .popup h2 {
    font-size: 18px;
  }
  .popup h3 {
    font-size: 15px;
  }
}

@media only screen and (max-width: 500px) {
  .popup-inner {
    /* height: 90vh; */
    padding: 10px;
    width: 95vw;
    margin-top: 2.5vw;
  }
  .popup {
    /* justify-content: flex-start; */
  }
  .popup-card {
    /* width: 40%; */
  }
}
