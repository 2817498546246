.landing-window {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  transition: 1s !important;
  overflow: hidden;
  will-change: transform;
}

.hide-animate {
  width: 0 !important;
}

.modal-logo {
  /* width: 200px; */
  /* max-width: 90%; */
  margin-top: 35vh;
  margin-top: calc(var(--vh, 1vh) * 35);
  display: block;
  margin-left: auto;
  margin-right: auto;
}
